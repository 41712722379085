<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-container fluid class="pa-0 pa-md-3 pa-xl-4 ">
      <v-card class="elevation-x py-md-6 pa-6">
        <v-fade-transition hide-on-leave>
          <v-skeleton-loader v-if="isLoading"
                             type="article, article,article, article,article, article,article, article"
          />
          <v-form v-else ref="form" lazy-validation>
            <div>
              <p>Avez vous une possibilité de téléconsultation ?</p>
              <v-radio-group v-model="model.teleconsultation" :mandatory="true">
                <v-radio label="Non" :value="false" />
                <v-radio label="Oui" :value="true" />
              </v-radio-group>
            </div>

            <div>
              <p>Continuez vous à consulter physiquement au cabinet pour vos patients si nécessaire ?</p>
              <v-radio-group v-model="model.physical_consultation1" :mandatory="true">
                <v-radio label="Non" :value="false" />
                <v-radio label="Oui" :value="true" />
              </v-radio-group>
            </div>

            <div>
              <p>Pouvez vous recevoir dans votre cabinet des patients avec des symptômes COVID19 ?</p>
              <v-radio-group v-model="model.symptomatic_patient_consultation" :mandatory="true">
                <v-radio label="Non" :value="false" />
                <v-radio label="Oui" :value="true" />
              </v-radio-group>
            </div>

            <div>
              <p>Êtes vous prêt(e)s dans les semaines à venir à voir physiquement des patients ?</p>
              <v-checkbox
                v-for="(label, value, index) in choices.physicalConsultation2"
                :key="index"
                v-model="model.physical_consultation2"
                :hide-details="index !== Object.keys(choices.physicalConsultation2).length - 1"
                :label="label" :value="value"
              />
            </div>

            <v-text-field
              v-model="model.specific_exam"
              label="Examens possibles en cabinet ? (ECG, etc)"
            />

            <div>
              <p>
                Êtes vous prêt(e)s dans les semaines à venir à téléconsulter pour des patients ou donner
                des avis téléphoniques ?
              </p>
              <v-checkbox
                v-for="(label, value, index) in choices.teleconsultation2"
                :key="index"
                v-model="model.teleconsultation2"
                :hide-details="index !== Object.keys(choices.teleconsultation2).length - 1"
                :label="label" :value="value"
              />
            </div>

            <div>
              <p>Calendrier de votre activité professionnelle</p>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" />
                      <th class="text-left">Matin</th>
                      <th class="text-left">Après-midi</th>
                      <th class="text-left">Soirée</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key, index) in model.availabilities" :key="index">
                      <td>{{ key }}</td>
                      <td>
                        <v-checkbox v-model="value.morning" />
                      </td>
                      <td>
                        <v-checkbox v-model="value.afternoon" />
                      </td>
                      <td>
                        <v-checkbox v-model="value.evening" />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>


            <div>
              <p>Pourriez vous être disponible au delà de ce calendrier en cas de besoin sanitaire?</p>
              <v-checkbox
                v-for="(label, value, index) in choices.availabilities2 "
                :key="index"
                v-model="model.availabilities2"
                :hide-details="index !== Object.keys(choices.availabilities2).length - 1"
                :label="label" :value="value"
              />
            </div>

            <div>
              <p>
                Seriez vous disponible pour prêter main forte à des établissements ou des institutions en
                cas de demande ?
              </p>
              <v-radio-group v-model="support.selected" :mandatory="true">
                <v-radio label="Oui" value="yes" />
                <v-radio label="Non" value="no" />
                <div class="d-flex flex-row">
                  <v-radio label="Autre :" value="maybe" />
                  <v-text-field v-model="support.iDontKnow" :rules="supportTextValidator"
                                class="ml-2 mr-2"
                  />
                </div>
              </v-radio-group>
            </div>

            <v-text-field v-model="model.comment" label="Facultatif - commentaires complémentaires" />

            <v-spacer class="mt-2" />
            <v-row class="text-center justify-center">
              <v-btn color="primary" small class="mr-4" :loading="isSubmitted" @click="onSubmit">
                Enregistrer
              </v-btn>
            </v-row>
          </v-form>
        </v-fade-transition>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import {rules} from '@/services/rules'
  import api from '@/api/api'
  import {empty} from '@/utils/variables'

  export default {
    name: 'CovidForm',
    data() {
      return {
        rules,
        isSubmitted: false,
        isLoading: true,
        itemsBreadcrumbs: [],
        model: {
          teleconsultation: false,
          physical_consultation1: false,
          symptomatic_patient_consultation: false,
          physical_consultation2: [],
          specific_exam: '',
          teleconsultation2: [],
          availabilities: {
            Lundi: {morning: false, afternoon: false, evening: false},
            Mardi: {morning: false, afternoon: false, evening: false},
            Mercredi: {morning: false, afternoon: false, evening: false},
            Jeudi: {morning: false, afternoon: false, evening: false},
            Vendredi: {morning: false, afternoon: false, evening: false},
            Samedi: {morning: false, afternoon: false, evening: false},
            Dimanche: {morning: false, afternoon: false, evening: false}
          },
          availabilities2: [],
          support: null,
          comment: ''
        },
        choices: {
          physicalConsultation2: {
            choice1: 'Gérer en présence physique les demandes de continuité de soin, urgences de vos patients',
            choice2: 'Voir si nécessaire physiquement des patients adressés par une plateforme de régulation (ex : centre 15, plate-forme libérale...)',
            choice3: 'Aller consulter dans des centres dédiés patients non covid si nécessaire',
            choice4: 'Aller consulter dans des centres dédiés patients covid si nécessaire'
          },
          teleconsultation2: {
            choice1: 'Teleconsulter pour demandes de continuité de soin, urgences de vos patientes',
            choice2: 'Teleconsulter pour de nouveaux patients prenant RV',
            choice3: 'Teleconsulter pour des patients adressés par des confrères',
            choice4: 'Teleconsulter pour des patients adressés par une plateforme de régulation (ex : centre 15, plate-forme libérale...)',
            choice5: 'Donner des avis téléphoniques à vos patients',
            choice6: 'Donner des avis téléphoniques à vos correspondants',
            choice7: 'Donner des avis téléphoniques à des médecins adressés par une plateforme de régulation'
          },
          availabilities2: {
            choice1: 'Non',
            choice2: 'Oui - en soirée',
            choice3: 'Oui - samedi',
            choice4: 'Oui - dimanche'
          }
        }
      }
    },

    computed: {
      supportTextValidator() {
        return 'maybe' === this.support.selected ? [!empty(this.support.iDontKnow) || 'Champ requis'] : []
      },

      support() {
        if (!this.model.support || 'Non' === this.model.support) return {selected: 'no', iDontKnow: ''}

        if ('Oui' === this.model.support) return {selected: 'yes', iDontKnow: ''}

        return {selected: 'maybe', iDontKnow: this.model.support}
      }
    },
    mounted() {
      this.itemsBreadcrumbs.push({text: 'Formulaire Coronavirus', disabled: true, to: '/'})
    },
    async created(){
      api.get('/v2/professionals/covid/' + this.$auth.user.sub)
        .then(response => {
          if (200 === response.data.status) this.model = response.data.model
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    methods: {
      getSupportValue() {
        if ('maybe' === this.support.selected) return this.support.iDontKnow

        if ('yes' === this.support.selected) return 'Oui'

        if ('no' === this.support.selected) return 'Non'
      },

      async onSubmit() {

        if (!this.$refs.form.validate()) {
          return
        }

        this.isSubmitted = true

        this.model.support = this.getSupportValue()

        let promise = this.model.uid ? api.put('/v2/professionals/covid', {covid_form: this.model}) : api.post('/v2/professionals/covid', {covid_form: this.model})

        let [err] = await promise
          .then(response => {
            this.model = response.data
            return [null, response.data]
          })
          .catch(error => {
            return [error.response, null]
          })
          .finally(() => {
            this.isSubmitted = false
          })

        if (err) {

          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue.'
          })

          return
        }
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Enregistrement terminé',
          subtitle: 'L\'enregistrement du formulaire a été fait avec succès.'
        })
      }
    }
  }
</script>
